/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/customs.scss";
@import 'material-icons/iconfont/material-icons.css';
@import 'animate.css/animate.css';
// @import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
// @import '../node_modules/@syncfusion/ej2-base/styles/material.css'; 
 

html {
    min-height: 100vh;
}

body {
    background: radial-gradient(79.3% 78.53% at 51.94% 52.18%, #1D53A4 0%, #000 100%);
    font-family: 'Roboto', sans-serif;
    color: #fff;
    overflow-y: hidden;
}

table {
    border-collapse: unset;
    border-spacing: 0px 10px;
}

.custom-modal {
    @extend .d-flex;
    @extend .align-items-center;
    height: 100vh;
    .modal-content {
        @extend .bg-card !optional;
        @extend .border-card !optional;
        @extend .p-5;
        @extend .clip4;
        backdrop-filter: blur(6px);
    };
}


.e-grid .e-icon-ascending::before {
  content: '\e306';
}
	
.e-grid .e-icon-descending::before {
  content: '\e304';
}

.modal-backdrop.show {
    opacity: 0.65;
}

.modal {
    overflow-y: hidden;
}

td, th {
    display: table-cell;
    vertical-align: middle;
}

.egrid {
  @extend .w-100;
}

.e-headercontent tbody, .e-headercontent caption {
  @extend .d-none;
}

th {
  @extend .border-0;
  @extend .bg-transparent;
  @extend .text-white;
  @extend .font-14;
  @extend .px-3 ;
  @extend .py-0;
  @extend .fw-light;
  @extend .text-faded;
}


tbody tr {
    border-radius: 10px !important;
    background: rgba(15, 28, 61, 0.75) !important;
    box-shadow: 1px 17px 44px 0px rgba(3, 2, 41, 0.07) !important;
    backdrop-filter: blur(25px) !important;
}

.e-grid, .e-table {
  @extend .w-100;
}

.e-table {
  border-spacing: 0px 2px;
  th {
    @extend .px-1;
  }
  tr {
    @extend .bg-transparent;
    .capsule {
      background-color: rgba(15, 28, 61, 0.75) !important;
      box-shadow: 1px 17px 44px 0px rgba(3, 2, 41, 0.07) !important;
      @extend .p-2;
      @extend .px-3;
      @extend .rounded;

    }
  }
}

.e-clipboard{
  @extend .d-none;
}

.e-content {
  overflow-y: hidden !important;
}

.user-card, .flip-card-back {
    @extend .bg-card !optional;
    @extend .border-card !optional;
}

.form-control:focus {
    box-shadow: none;
}

.form-label {
  top: -9px;
  left: 8px;
}

.mat-icon {
    font-family: 'Material Icons' !important;  
}

.comp-img {
  width: 16px;
  height: 16px;
}

.edge-top {
    position: relative;
}

.popup-title {
    border-bottom-right-radius: 20px;
}

.popup-curve {
    border-top-left-radius: 24px;
}

.status {
    font-size: 12px;
    width: 55%;
    text-align: center;
    padding-left: 10% !important;
}

.clip2 {
    clip-path: polygon(-24px -24px, calc(100% - 27px) 0, 100% calc(100% - 280px), 115% 113%, 0 107%, -10px 101%);
}

.clip4 {
    clip-path: polygon(0 0, calc(100% - 24px) 0, 100% 24px, 100% 100%, 100% 100%, 0 100%);
}

.clip3 {
    clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 12px, 100% 100%, 12px 100%, 0 calc(100% - 12px));
}

.scroll-y-container {
    height: calc(100vh - 165px);
    overflow-y: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 10px;
}
  
::-webkit-scrollbar-track {
    background: transparent;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}
  
::-webkit-scrollbar-thumb {
    background: #060B26;
    border-left: 1px solid #4FC8EC;
    border-right: 1px solid #4FC8EC;
    border-radius: 32px;
}

// .content-wrapper:after {
//     width: 5px;
//     content: "";
//     height: calc(100% - 200px);
//     position: absolute;
//     right: 4px;
//     z-index: -2;
//     bottom: 0;
//     border: 1px solid #4FC8EC;
// }

.form-check-input:checked {
  @extend .border-light-blue;
  border-width: 2px;
}

.form-check-input, .form-check-input:checked {
  @extend .bg-transparent;
}

.form-check-input {
  border-width: 2px;
  width: 20px;
  height: 20px;
}

.card-selection {
  

/* Style for custom checkbox */
label {
    position: relative;
    display: inline-block;
    width: 18px; /* Adjust as needed */
    height: 18px; /* Adjust as needed */
    cursor: pointer;
}

label::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #4FC8EC; /* Border color */
    transform: rotate(45deg);
    box-sizing: border-box;
}

label[data-is-checked="true"]::before {
    background-color: #4FC8EC;
}

label[data-is-checked="true"]::after {
    content: "";
    position: absolute;
    top: 47%;
    left: 50%;
    width: 6px;
    height: 11px;
    border-bottom: 3px solid #060B26;
    border-right: 3px solid #060B26;
    transform: translate(-50%, -50%) rotate(45deg);
}


}

  //// skeleton loader
  
.movie--isloading {
    overflow: hidden;
    @extend .mb-3;
}
  
.movie--isloading .loading-image {
  height: 200px;
  background-image: -webkit-linear-gradient(90deg, #060b2657 0px, #060b2654 40px, #060b2645 80px);
  background-image: -o-linear-gradient(90deg, #060b2657 0px, #060b2654 40px, #060b2645 80px);
  background-image: linear-gradient(90deg, #060b2657 0px, #060b2654 40px, #060b2645 80px);
  background-size: 250px;
  -webkit-animation: shine-loading-image 1.5s infinite ease-out;
          animation: shine-loading-image 1.5s infinite ease-out;
}

.movie--isloading .loading-content {
  @extend .p-3;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
}

.movie--isloading .loading-content .loading-text-container {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}

.movie--isloading .loading-content .loading-main-text {
  height: 10px;
  width: 65%;
  @extend .mb-2;
  @extend .rounded;
  background: #ececec;
  background-image: -webkit-linear-gradient(90deg, rgb(6 11 38 / 48%) 0px, rgb(6 11 38 / 40%) 40px, rgb(40 47 83 / 72%) 80px);
  background-image: -o-linear-gradient(90deg, rgb(6 11 38 / 48%) 0px, rgb(6 11 38 / 40%) 40px, rgb(40 47 83 / 72%) 80px);
  background-image: linear-gradient(90deg, rgb(6 11 38 / 48%) 0px, rgb(6 11 38 / 40%) 40px, rgb(40 47 83 / 72%) 80px);
  background-size: 250px;
  -webkit-animation: shine-loading-container-items 1.5s infinite ease-out;
          animation: shine-loading-container-items 1.5s infinite ease-out;
}

.movie--isloading .loading-content .loading-sub-text {
  height: 10px;
  @extend .w-50;
  background: #ececec;
  background-image: -webkit-linear-gradient(90deg, rgb(6 11 38 / 48%) 0px, rgb(6 11 38 / 40%) 40px, rgb(40 47 83 / 72%) 80px);
  background-image: -o-linear-gradient(90deg, rgb(6 11 38 / 48%) 0px, rgb(6 11 38 / 40%) 40px, rgb(40 47 83 / 72%) 80px);
  background-image: linear-gradient(90deg, rgb(6 11 38 / 48%) 0px, rgb(6 11 38 / 40%) 40px, rgb(40 47 83 / 72%) 80px);
  background-size: 250px;
  @extend .rounded;
  -webkit-animation: shine-loading-container-items 1.5s infinite ease-out;
          animation: shine-loading-container-items 1.5s infinite ease-out;
}

.movie--isloading .loading-content .loading-btn {
  width: 60px;
  height: 25px;
  background: #ececec;
  background-image: -webkit-linear-gradient(90deg, rgb(6 11 38 / 48%) 0px, rgb(6 11 38 / 40%) 40px, rgb(40 47 83 / 72%) 80px);
  background-image: -o-linear-gradient(90deg, rgb(6 11 38 / 48%) 0px, rgb(6 11 38 / 40%) 40px, rgb(40 47 83 / 72%) 80px);
  background-image: linear-gradient(90deg, rgb(6 11 38 / 48%) 0px, rgb(6 11 38 / 40%) 40px, rgb(40 47 83 / 72%) 80px);
  background-size: 250px;
  @extend .rounded;
  -webkit-animation: shine-loading-container-items 1.5s infinite ease-out;
          animation: shine-loading-container-items 1.5s infinite ease-out;
}


@-webkit-keyframes shine-loading-image {
  0% {
    background-position: -80px;
  }
  40%, 100% {
    background-position: 240px;
  }
}


@keyframes shine-loading-image {
  0% {
    background-position: -80px;
  }
  40%, 100% {
    background-position: 240px;
  }
}

@-webkit-keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 440px;
  }
}

@keyframes shine-loading-container-items {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 440px;
  }
}

//accordion overrides

.panel-group .panel.card {
  @extend .bg-transparent;
}

.panel-group .panel-body.card-block.card-body {
  @extend .p-0;
}

.panel-group .btn.btn-link {
  @extend .text-white;
  @extend .text-decoration-none;
  @extend .p-0;
  @extend .fw-light;
}

.panel-group .panel-heading.card-header {
  @extend .ps-2;
  @extend .pe-0;
}


.panel-open > .accord .opened-outer, .panel-open > .programs-accord .opened, .panel-open > .personas-accord .opened, .panel-open > .teams-accord .opened {
  @extend .d-block;
}

.panel-open > .accord .closed-outer, .panel-open > .programs-accord .closed, .panel-open > .personas-accord .closed, .panel-open > .teams-accord .closed {
  @extend .d-none;
}

.panel-open > .accord .filter-name-main, .panel-open > .programs-accord .filter-name, .panel-open > .personas-accord .filter-name, .panel-open > .teams-accord .filter-name {
  @extend .text-light-blue;
}

.opened-outer, .opened {
  @extend .d-none;
}


//popover overrides

.popover {
  @extend .clip3;
  @extend .rounded-0;
  @extend .shadow;
  width: 145px;
  left: calc(100% - 154px) !important;
  top: 15px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  left: none !important;
  background-color: rgb(48, 73, 117);
}

.popover-content {
  @extend .py-2;
}

.popover-arrow {
  @extend .d-none;
}

.modal-content {
  @extend .overflow-hidden;
}

.form-modal {
  @extend .modal-lg;
  @extend .d-flex;
  @extend .align-items-center;
  height: 100vh;
  @extend .mx-auto;
  @extend .my-0;
}

.active {
  @extend .text-light-blue;
  .user-management-icon-blue, .team-management-icon-blue {
    @extend .d-inline-block;
  }
  .user-management-icon-white, .team-management-icon-white, .filter-icon-main, .filter-icon {
    @extend .d-none;
  }
}

.user-management-icon-blue, .team-management-icon-blue, .panel-open > .accord .filter-icon-main, .panel-open > .programs-accord .filter-icon, .panel-open > .personas-accord .filter-icon, .panel-open > .teams-accord .filter-icon, .active-filter-icon-main, .active-filter-icon{
  @extend .d-none;
}
.user-management-icon-white, .team-management-icon-white,  .panel-open > .accord .active-filter-icon-main, .panel-open > .programs-accord .active-filter-icon, .panel-open > .personas-accord .active-filter-icon, .panel-open > .teams-accord .active-filter-icon, .filter-icon-main, .filter-icon {
  @extend .d-inline-block;
}

.legend-like-header {
  z-index: 9;
  top: -8px;
  left: 8px;
}

.legend-like-header > div:after {
  content: '';
  @extend .position-absolute;
  @extend .w-100;
  @extend .h-50;
  @extend .top-0;
  @extend .start-0;
  @extend .bg-dark-blue;
  z-index: -1;
}

.tabHighlight, .tabHighlight.border-light-blue {
  @extend .bg-light-blue;
  @extend .text-secondary;
}

.overflow-wrap {
  max-height: calc(100vh - 374px);
  min-height: calc(100vh - 374px);
  overflow-x: hidden;
}

@media screen and (max-width: 1080px) {
  #user-filter-status-indicator {
    display: none !important;
  }
  .team-cards-wrapper {
    flex-wrap: nowrap;
  }
  .team-cards-wrapper .col {
    flex-basis: 175px;
  }
  .scroll-y-container {
    overflow-x: scroll;
  }
}