// $primary:       red !default;
$secondary:        #060B26;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

@import "./../../../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');


.bg-green {
    background-color: rgba(36, 255, 0, 0.15);
}

.bg-green-solid {
    background-color: rgba(36, 255, 0, 1);
}

.border-green {
    border: 1px solid rgba(36, 255, 0, 1);
}

.border-form-control {
    border: 1px solid rgba(204, 204, 204, 0.50);
}

.b-bottom {
    border-bottom: 1px solid rgba(204, 204, 204, 0.20);
}

.text-green {
    color: rgba(36, 255, 0, 1);
}

.bg-blue {
    background-color: rgba(0, 163, 255, 0.15);
}

.bg-blue-solid {
    background-color: rgba(0, 163, 255, 1);
}

.border-blue {
    border: 1px solid rgba(0, 163, 255, 1);
}

.text-blue {
    color: rgba(0, 163, 255, 1);
}

.border-light-blue {
   border: 1px solid #4FC8EC
}

.text-light-blue {
    color: #4FC8EC
 }

.bg-light-blue {
    background-color: #4FC8EC
}

.bg-red {
    background-color: rgba(232, 84, 84, 0.15);
}

.bg-red-solid {
    background-color: rgba(232, 84, 84, 1);
}

.border-red {
    border: 1px solid rgba(232, 84, 84, 1);
}

.bg-card {
    background-color: rgba(15, 28, 61, 0.70);
}

.bg-search {
    background-color: rgba(255, 255, 255, 0.18);
}

.text-red {
    color: rgba(232, 84, 84, 1);
}

.bg-purple {
    background-color: rgba(153, 136, 255, 0.15);;
}

.bg-purple-solid {
    background-color: rgba(153, 136, 255, 1);;
}

.border-purple {
    border: 1px solid rgba(153, 136, 255, 1);
}

.text-purple {
    color: rgba(153, 136, 255, 1);;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-10 {
    font-size: 10px;
}

.font-20 {
    font-size: 20px; 
}

.font-24 {
    font-size: 24px;
}

.font-30 {
    font-size: 30px;
}

.font-35 {
    font-size: 35px;
}

.border-faded {
    border: 1px solid #A0AEC0;
}

.text-faded {
    color: #A0AEC0;
}

.text-orange {
    color: #FF9C27
}

.btn-standard {
    width: 150px;
}

.width-80 {
    width: 50px;
}

.width-72 {
    width: 72px;
}

.height-45 {
    height: 45px;
}

.width-85 {
    width: 85px;
}

.padding-1 {
    padding: 1px;
}

.padding-1sm {
    padding: 0.6px;
}

.bg-gradient-blue {
    background: linear-gradient(180deg, rgb(50 80 137 / 87%), rgb(50 80 133 / 73%));
}

.bg-dark-blue {
    background-color: #112D5E;
}

.bg-dark-blue2 {
    background-color: #1D53A4;
}

.bg-gradient-grey {
    background: linear-gradient(180deg, rgba(165, 164, 167, 0.3803921569), #4c4a4a8a);
}

.corner:after {
    content: '';
    position: absolute;
    width: 53px;
    height: 35px;
    transform: rotate(40deg);
    right: -34px;
    top: -22px;
}

.padding-1sm.clip3:hover {
    translate: -6px -6px;
}

.padding-1sm.clip3 {
    transition: ease .3s;
}

.corner:before {
    content: "";
    position: absolute;
    width: 53px;
    height: 35px;
    transform: rotate(-45deg);
    left: -43px;
    bottom: -27px;
}

.corner.border-red:after, .corner.border-red:before {
    @extend .bg-red-solid;
}

.corner.border-light-blue:after, .corner.border-light-blue:before {
    @extend .bg-light-blue;
}
.corner.border-purple:after, .corner.border-purple:before {
    @extend .bg-purple-solid;
}

.corner.border-blue:after, .corner.border-blue:before {
    @extend .bg-blue-solid;
}

.corner.border-green:after, .corner.border-green:before {
    @extend .bg-green-solid;
}

.btn.bg-light-blue:hover, .btn.bg-light-blue:disabled {
    @extend .bg-light-blue;
    @extend .text-secondary;
}

.btn.bg-light-blue:disabled {
    opacity: .3;
}

.btn.border-red.text-red:hover {
    @extend .border-red;
    @extend .text-red;
}

.btn.border-light-blue.text-light-blue:hover {
    @extend .border-light-blue;
    @extend .text-light-blue;
}
